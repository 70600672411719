<template>
    <BasePopup v-if="isVisible" id="cookie-banner" @close="close">
        <p class="h3" style="margin-top: 0">
            {{ t.auth.cookie_banner_title }}
        </p>

        <p>
            {{ t.auth.cookie_banner_text }}
            <a
                :href="globalRoute.data_policy"
                @click.prevent="goToHref(globalRoute.data_policy)"
                rel="noreferrer noopener"
                >{{ t.auth.cookie_banner_text_2 }}</a
            >
        </p>

        <div class="button-bar">
            <a
                :href="globalRoute.data_policy"
                class="button button-light-grey button-high"
                @click.prevent="goToHref(globalRoute.data_policy)"
            >
                {{ t.auth.cookie_banner_save_selection }}
            </a>

            <a :href="'/' + globalRoute.locale" class="button button-primary button-high" @click.prevent="close">
                {{ t.auth.cookie_banner_accept_all }}
            </a>
        </div>

        <div style="margin-bottom: 10px"></div>
    </BasePopup>
    <BasePopup v-else-if="advertisments" id="cookie-banner" class="advertisments" @close="close" :back-drop="true" :is-closable="false">
        <p class="h3" style="margin-top: 0">{{ t.auth.cookie_banner_2_title }}</p>
        <div class="advertisments__wrapper">
            <div class="advertisments__left">
                <p class="h4">{{ t.auth.cookie_banner_2_accept_headline }}</p>
                <p>{{ t.auth.cookie_banner_2_accept_paragraf_1 }}</p>
                <p>{{ t.auth.cookie_banner_2_accept_paragraf_2 }} <a :href="globalRoute.data_policy" target="_blank">{{ t.content.datenschutz }}</a>, <a :href="globalRoute.imprint" target="_blank">{{ t.content.impressum }}</a>.</p>
                <BaseButton type="high" color="alternate" @click="acceptCookie">{{ t.auth.cookie_banner_2_accept_button }}</BaseButton>
            </div>
            <div class="advertisments__right">
                <p class="h4">{{ t.auth.cookie_banner_2_register_headline }}</p>
                <p>{{ t.auth.cookie_banner_2_register_paragraf }}</p>
                <ul>
                    <li>{{ t.auth.cookie_banner_2_register_list_1 }}</li>
                    <li>{{ t.auth.cookie_banner_2_register_list_2 }}</li>
                    <li>{{ t.auth.cookie_banner_2_register_list_3 }}</li>
                </ul>
                <BaseButton @click.prevent="routeRegister">{{ t.auth.register_for_free }}</BaseButton>
                <div class="login-form">
                    {{ t.auth.cookie_banner_2_member }} <a href="#" @click.prevent="routeLogin">{{ t.booking.log_in }}</a>
                </div>
            </div>
        </div>
    </BasePopup>
</template>

<script>
import BaseButton from "@/components/Buttons/BaseButton.vue"
import BasePopup from "@/components/Frontend/BasePopup.vue"
import { openLoginRegistrationPopup } from "@/libs/helpers.js"

export default {
    name: "CookieBanner",
    components: { BasePopup, BaseButton },
    props: [],
    data() {
        return {
            t: window.globalJs.translations,
            globalRoute: window.globalJs.route,
            isVisible: false,
            advertisments: false,
        }
    },
    async created() {
        const pageIds = ["802", "247", "401"]

        if (localStorage && localStorage.getItem("cookie-banner")) {
            //console.log('we have cookies')
        } else {
            //console.log('empty cookies')
            if (document.body.dataset.userId) {
                if (!pageIds.includes(document.body.dataset.pageId)) {
                    this.isVisible = true
                }
            } else {
                if (!pageIds.includes(document.body.dataset.pageId) && !document.body.dataset.postId && !document.body.dataset.helpId && !document.body.dataset.tutorialId) {
                    this.isVisible = true
                }
                if (document.body.dataset.postId || document.body.dataset.helpId || document.body.dataset.tutorialId) {
                    this.advertisments = true
                    document.body.style.overflow = "hidden"
                }
            }
        }
    },
    computed: {},
    methods: {
        goToHref(href) {
            localStorage.setItem("cookie-banner", "true")
            window.location.href = href
        },
        close() {
            this.isVisible = false
            this.advertisments = false
            document.body.removeAttribute("style")
            localStorage.setItem("cookie-banner", "true")
        },
        routeLogin() {
            document.querySelector("#btn-login").click()
            openLoginRegistrationPopup()
        },
        routeRegister() {
            document.querySelector("#btn-login").click()
            openLoginRegistrationPopup("registration")
        },
        acceptCookie() {
            localStorage.setItem("cookie-banner", "true")
            localStorage.setItem("advertisements-accepted", "true")
            this.isVisible = false
            this.advertisments = false
            document.body.removeAttribute("style")
            this.loadGoogleAdsScript()
        },

        loadGoogleAdsScript() {
            const nonce = document.querySelector("meta[name='csp-nonce']").getAttribute("content")
            const gtagScript1 = document.createElement("script")
            gtagScript1.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9111835927498487`
            gtagScript1.async = true
            gtagScript1.crossOrigin = "anonymous"
            gtagScript1.setAttribute("nonce", nonce)

            document.head.appendChild(gtagScript1)

            const gtagScript3 = document.createElement("script")
            gtagScript3.src = `https://www.googletagmanager.com/gtag/js?id=G-GBHMGRDLTW`
            gtagScript3.crossOrigin = "anonymous"
            gtagScript3.setAttribute("nonce", nonce)
            document.head.appendChild(gtagScript3)

            const gtagScript2 = document.createElement("script")
            gtagScript2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GBHMGRDLTW');`
            gtagScript2.setAttribute("nonce", nonce)

            document.head.appendChild(gtagScript2)
        },
    },
}
</script>
